<template>
  <div :class="{ white: white }" class="loader">
    <svg :class="{ 'circular-s': small }" class="circular" viewBox="25 25 50 50">
      <circle
        cx="50"
        cy="50"
        r="10"
        class="path"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CircularLoader',
  props: {
    small: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: relative;
  margin: $space-64 auto;
  width: 4rem;
}

.loader::before {
  content: '';
  display: block;
  padding-top: 150%;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 3s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0%,
  40%,
  66%,
  80%,
  90% {
    stroke: $blue;
  }
}

/* Small Circular Loader */
.circular-s {
  height: 50%;
  width: auto;

  .path {
    animation: dash 1.5s ease-in-out infinite, color-w 6s ease-in-out infinite;
  }
}

@keyframes color-w {
  0%,
  100% {
    stroke: rgba(255, 255, 255, 0.8);
  }
}

.white {
  .path {
    animation: dash 1.5s ease-in-out infinite, color-w 6s ease-in-out infinite;
  }
}
</style>
