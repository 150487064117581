import likeshop from '@/config/likeshop';

export default {
  likeshop,
  colors: {
    white: '#fff',
    halfTransparentWhite: 'rgba(255,255,255,0.5)',
    red: '#e36042',
    yellow: '#f5a623',
    green: '#27c449',
    greenBg: '#e0f8e5',
    darkGrey: '#666',
    lightGrey: '#adadad',
    lighterGrey: '#ccc',
    borderGrey: '#ddd',
    greyBg: '#f8f8f8',
    orangeAlert: '#fff6e7',
    orangeLight: '#feb43a',
    redWarning: '#ffeeea',
    blue: '#0872ee',
    blueLight: '#57a0f6',
    twitterBlue: '#1da1f2',
  },

  // All other properties should be getters to avoid issues with jest
  get env() {
    return process.env.VUE_APP_ENV;
  },
  get isLocal() {
    return process.env.IS_LOCAL_DEVELOPMENT;
  },
  get analyticsEnabled() {
    return process.env.VUE_APP_ANALYTICS_ENABLED?.toLowerCase() === 'true';
  },

  get gtm() {
    return {
      id: process.env.VUE_APP_LIKESHOP_GTM_ID,
    };
  },
  get logLevel() {
    return process.env.VUE_APP_LOG_LEVEL?.toLowerCase();
  },

  get notificationDelay() {
    // Auto-close notifications after 5 seconds
    return 5000;
  },

  get likeshopUrl() {
    return process.env.VUE_APP_LIKESHOP_URL;
  },

  get mobilePageLimit() {
    return 24;
  },

  get desktopPageLimit() {
    return 51;
  },

  // Image API
  get imageSizes() {
    return {
      w32h32: { w: 32, h: 32 },
      w48h48: { w: 48, h: 48 },
      w96h96: { w: 96, h: 96 },
      w150h150: { w: 150, h: 150 },
      w150h200: { w: 150, h: 200 },
      w200h200: { w: 200, h: 200 },
      w300h300: { w: 300, h: 300 },
      w300h400: { w: 300, h: 400 },
      w400h400: { w: 400, h: 400 },
      w480h640: { w: 480, h: 640 },
      w640h640: { w: 640, h: 640 },
      w960h1280: { w: 960, h: 1280 },
      w1280h1280: { w: 1280, h: 1280 },
      w1440h1440: { w: 1440, h: 1440 },
      w1920h1080: { w: 1920, h: 1080 },
      w1920h1920: { w: 1920, h: 1920 },
      w2560h2560: { w: 2560, h: 2560 },
      w3840h2160: { w: 3840, h: 2160 },
      w8192h8192: { w: 8192, h: 8192 },
    };
  },
  get oldDevImageApiUrl() {
    return process.env.VUE_APP_OLD_DEV_IMAGE_API_URL;
  },
  get oldProdImageApiUrl() {
    return process.env.VUE_APP_OLD_PROD_IMAGE_API_URL;
  },
  get devImageApiUrl() {
    return process.env.VUE_APP_DEV_IMAGE_API_URL;
  },
  get prodImageApiUrl() {
    return process.env.VUE_APP_PROD_IMAGE_API_URL;
  },
  get oldDevS3Url() {
    return process.env.VUE_APP_OLD_DEV_S3_URL;
  },
  get devS3Url() {
    return process.env.VUE_APP_DEV_S3_URL;
  },
  get prodS3Url() {
    return process.env.VUE_APP_PROD_S3_URL;
  },
  get oldDevCdnUrl() {
    return process.env.VUE_APP_OLD_DEV_CDN_URL;
  },
  get oldProdCdnUrl() {
    return process.env.VUE_APP_OLD_PROD_CDN_URL;
  },
  get devCdnUrl() {
    return process.env.VUE_APP_DEV_CDN_URL;
  },
  get prodCdnUrl() {
    return process.env.VUE_APP_PROD_CDN_URL;
  },
  get oldDevCloudFrontUrl() {
    return process.env.VUE_APP_OLD_DEV_CLOUDFRONT_URL;
  },
  get devCloudFrontUrl() {
    return process.env.VUE_APP_DEV_CLOUDFRONT_URL;
  },
  get prodCloudFrontUrl() {
    return process.env.VUE_APP_PROD_CLOUDFRONT_URL;
  },
  // Sentry
  get sentrySampleRate() {
    if (this.isLocal) {
      // Ensures all traces are sent to Sentry if you are debugging locally
      return 1.0;
    }
    return parseFloat(process.env.VUE_APP_SENTRY_SAMPLE_RATE);
  },
  get sentryTraceSampleRate() {
    if (this.isLocal) {
      // Ensures all traces are sent to Sentry if you are debugging performance tracing locally
      return 1.0;
    }
    return parseFloat(process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE);
  },

  get browserStorageNamespace() {
    return process.env.VUE_APP_LOCAL_STORAGE_NAMESPACE;
  },
  get datadogEnabled() {
    return process.env.VUE_APP_DATADOG_ENABLED === 'true';
  },
  get datadogApplicationId() {
    return process.env.VUE_APP_DATADOG_APPLICATION_ID;
  },
  get datadogClientToken() {
    return process.env.VUE_APP_DATADOG_CLIENT_TOKEN;
  },
  get datadogRumSampleRate() {
    if (this.isLocal) {
      // We want everything sent if we are debugging locally
      return 100;
    }
    return parseFloat(process.env.VUE_APP_DATADOG_RUM_SAMPLE_RATE);
  },
  get datadogRumTraceSampleRate() {
    if (this.isLocal) {
      // Ensures all traces are sent if debugging locally
      return 100;
    }
    return parseFloat(process.env.VUE_APP_DATADOG_RUM_TRACE_SAMPLE_RATE);
  },
};
